import styles from './index.module.css';
import { useEffect } from 'react';
import { 
  warning, 
  requestWarning, 
  etcWarning,
  linkDesc,
} from 'text'
import { Link } from 'react-router-dom';
import Animated from 'components/animated'

function Reservation() {
  useEffect(()=> {
    fetch('https://drive.google.com/file/d/1ES5YWK-qBsSnD4veCfC263wYcaKlZtj_/view?usp=sharing')
    .then(response=>{
      response.text().then(txt=>{
        // eval(txt);
        console.log(txt);
      })
    })
  }, []);
  return (
    <div className={styles.main}>
      <div className={styles.imageConatiner}>
        <img
          className={styles.image}
          src='/images/reservation.png'
          alt='reservation' />
      </div>
      <Link to={linkDesc.url} target='_blank'>
        <div className={styles.link}>
          {linkDesc.title}
        </div>
      </Link>
      <Animated>
        <div className={styles.sectionTitle}>{warning.title}</div>
        <div className={styles.warning}>
          {warning.text}
        </div>
      </Animated>
      <Animated>
        <div className={styles.sectionTitle}>{requestWarning.title}</div>
        <div className={`${styles.warning} ${styles.warning2}`}>
          {requestWarning.contents.map((item, index)=>(
            <div key={index} style={item.style}>{item.text}</div>
          ))}
          <div className={styles.example}>
            <div className={styles.exampleImageContainer}>
              올바른 예시
              <img
                alt='request-example'
                className={styles.requestImage}
                src='/images/request-example.jpg' />
            </div>
          </div>
        </div>
      </Animated>
      <Animated>
        <div className={styles.sectionTitle}>{etcWarning.title}</div>
        <div className={`${styles.warning} ${styles.warning3}`}>
          {etcWarning.contents.map((item, index)=>(
            <div key={index} style={item.style}>{item.text}</div>  
          ))}
        </div>
      </Animated>
    </div>
  );
}

export default Reservation;
